import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step1 ({ theme, values, isLoading, handleFieldChange }) {
    const { t } = useTranslation(transDomain)

    return (
        <>
            <Steps
                step={2}
                steps={3}
                description={t('description')}
                title={''}
            />

            <Form.Row>
                <Form.RowColumns className={styles.rowColumn} columnsMobile={2}>
                    <Form.RowColumn size={0.7} className={styles.row}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            onChange={value => {
                                handleFieldChange('addressLine1', value)
                            }}
                            required={true}
                            type='text'
                            label={t('label.addressLine1')}
                            placeholder={t('placeholder.addressLine1')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.3} className={styles.row0}>
                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => {
                                handleFieldChange('addressLine2', value)
                            }}
                            type='text'
                            required={false}
                            label={t('label.addressLine2')}
                            placeholder={t('placeholder.addressLine2')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={1} columnsMobile={1}>
                    <Form.RowColumn size={1} className={styles.row1}>
                        <Form.Field
                            defaultValue={values.city || null}
                            onChange={value => {
                                handleFieldChange('city', value)
                            }}
                            required={true}
                            type='text'
                            label={t('label.city')}
                            placeholder={t('placeholder.city')}
                         />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={2} columnsMobile={2}>
                    <Form.RowColumn size={0.7} className={styles.row2}>
                        <Form.Field
                            label={t('label.province')}
                            placeholder={t('placeholder.province')}
                            defaultValue={values.province}
                            onChange={value => {
                                handleFieldChange('province', value)
                            }}
                            type='text'
                            required={true}
                         />
                    </Form.RowColumn>

                    <Form.RowColumn size={0.3} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.postalCode || null}
                            onChange={value => {
                                handleFieldChange('postalCode', value)
                            }}
                            type='number'
                            required={true}
                            label={t('label.postalCode')}
                            placeholder={t('placeholder.postalCode')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continue')}
                        style={{
                            background: theme.background,
                            color: theme.color,
                            marginTop: '0.5em',
                            marginBottom: '1.5em'
                        }}
                    />
                </MediaQuery>

                <MediaQuery maxWidth={1023}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continueAlternate')}
                        style={{
                            background: theme.background,
                            color: theme.color,
                            marginTop: '0.5em',
                            marginBottom: '1.5em'
                        }}
                />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step1.defaultProps = {
    values: {
        addressLine1: null,
        postalCode: null,
        city: null,
        province: null,
        addressLine2: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step1))
