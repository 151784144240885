import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import { Redirect } from 'react-router-dom'
import { Store } from 'Store'
import Steps from 'components/Steps'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import themes from './themes/__supportedThemes.js'
import AuthorizeNetForm from 'components/Payments/AuthorizeNetForm'

export default withTheme(themes)(({ history, location, match, theme }) => {
    const { state } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const paymentForm = React.useMemo(() => {
        if (typeof state.campaign['payment-gateways']['authorize.net'] !== 'undefined') {
            return (
                <AuthorizeNetForm onSuccess={() => {
                    setSubmitted(true)
                    window.scrollTo(0, 0)
                }}/>
            )
        }
    }, [state.campaign])

    if (submitted) {
        return <Redirect to={`success${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    const firstName = state.lead.fields.firstName
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <BodyLayout className={styles.container}>
            <LeftSide title={<span>{firstName}, thank you for helping us <strong>change lives.</strong></span>} className={styles.imgContainer} style={{
                backgroundImage: isIE11 && `url(${theme.sideDesktop})`,
                '--background-image-desktop': `url(${theme.sideDesktop})`,
                '--background-image-mobile': `url(${theme.sideMobile})`
            }}>
            </LeftSide>
            <RightSide className={styles.rightSide} style={{ backgroundColor: theme.backgroundColor }}>
                <Steps
                    step={1}
                    steps={3}
                    description={<div><strong>Payment - </strong>Billing address.</div>}
                />
                {paymentForm}
            </RightSide>
        </BodyLayout>
    )
})
