// @flow
import React from 'react'
import {
    BodyLayout,
    LeftSide,
    RightSide
} from 'components/MainLayout'
import Landing from 'components/Landing'
import withTheme from 'hoc/withTheme'
import themes, { type Theme } from './themes/__supportedThemes'

import mask from 'themes/images/mask.png'

import styles from './styles.module.scss'

function LandingPage (props: { history: any, location: any, match: any, theme: Theme }) {
    return (
        <BodyLayout className={styles.container}>
            <LeftSide className={styles.imgContainer} style={{
                backgroundImage: `url(${props.theme.sideDesktop})`
            }}/>
            <RightSide style={{
                backgroundColor: props.theme.backgroundColor
            }}>
                <div className={styles.bgImg} style={{ '--left-side-image': `url(${props.theme.sideMobile})` }}>
                    <div className={styles.mask} style={{ backgroundImage: `url(${mask})` }}/>
                </div>

                <Landing
                    onSubmit={(data, done) => {
                        window.scrollTo(0, 0)
                        props.history.push(`${props.match.params.url}/step-1${props.location.search}`)
                    }}
                    initialValues={{}}
                />
            </RightSide>
        </BodyLayout>
    )
}

export default withTheme(themes)(LandingPage)
