import * as React from 'react'
import withTheme from 'hoc/withTheme'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store.js'
import StepLayout from 'components/StepLayout'

import withForm from 'components/Form/Helpers/FormHOC'
import supportedThemes from './themes/__supportedThemes'
import styles from './styles.module.scss'
import Typography from '../../Typography'

function Success () {
    const { state } = React.useContext(Store)
    const { firstName, donationAmount } = state.lead.fields
    const currentValue = donationAmount
    const currencyFormatter = Currency(state.currency, state.locale)
    return (
        <StepLayout>
            <div className={styles.success}>
                <Typography isCentered>
                    <h1 className={styles.text}>
                        Thank you, {firstName}.
                        with your <br /> {currencyFormatter.format(currentValue)} donation,
                        we can help  <br /> make community dreams come true.
                    </h1>
                </Typography>
            </div>
        </StepLayout>
    )
}

export default withForm()(withTheme(supportedThemes)(Success))
