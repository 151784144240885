import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

function Step5 ({ theme, values, isLoading, handleFieldChange }) {
    const { t } = useTranslation(transDomain)
    const sameAsContact = !values.sameAsContact ? null : true
    return (
        <>
            <Steps
                step={2}
                steps={3}
                description={<div><strong>Payment - </strong>Billing address.</div>}
            />

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <span className={styles.chekbox}>
                            <Form.Checkbox
                                defaultChecked={sameAsContact}
                                onChange={() => handleFieldChange('sameAsContact', !values.sameAsContact)}
                            />
                            <p>Same as confirmed contact information.</p>
                        </span>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={3} columnsMobile={2}>
                    <Form.RowColumn size={0.7} className={styles.row1}>
                        <Form.Field
                            value={sameAsContact ? values.addressLine1 : values.billingAddress}
                            onChange={value => {
                                handleFieldChange(sameAsContact ? 'addressLine1' : 'billingAddress', value)
                            }}
                            type='text'
                            label={t('label.address')}
                            placeholder={t('placeholder.adress')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.3} className={styles.row2}>
                        <Form.Field
                            value={sameAsContact ? values.suiteAptNum : values.billingSuiteAptNum}
                            onChange={value => {
                                handleFieldChange(sameAsContact ? 'suiteAptNum' : 'billingSuiteAptNum', value)
                            }}
                            required={false}
                            type='number'
                            label={t('label.suiteAptNum')}
                            placeholder={t('placeholder.suiteAptNum')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            value={sameAsContact ? values.city : values.billingCity}
                            onChange={value => {
                                handleFieldChange(sameAsContact ? 'city' : 'billingCity', value)
                            }}
                            type='text'
                            label={t('label.city')}
                            placeholder={t('placeholder.city')}
                />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.7} className={styles.row4}>
                        <Form.Field
                            label={t('label.state')}
                            placeholder={t('placeholder.state')}
                            value={sameAsContact ? values.province : values.billingState}
                            onChange={value => {
                                handleFieldChange(sameAsContact ? 'province' : 'billingState', value)
                            }}
                            type='text'
                            required={false}
                         />
                    </Form.RowColumn>
                    <Form.RowColumn size={0.3} className={styles.row5}>
                        <Form.Field
                            value={sameAsContact ? values.postalCode : values.billingZipCode}
                            onChange={value => {
                                handleFieldChange(sameAsContact ? 'postalCode' : 'billingZipCode', value)
                            }}
                            type='number'
                            label={t('label.zip')}
                            placeholder={t('placeholder.zip')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('label.continue')}
                        style={{ background: theme.background, color: theme.color, marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('label.continueAlternate')}
                        style={{ background: theme.background, color: theme.color, marginTop: '0.5em', marginBottom: '1.5em' }}
                />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step5.defaultProps = {
    values: {
        billingAddress: null,
        billingSuiteAptNum: null,
        billingCity: null,
        billingState: null,
        billingZipCode: null,
        addres: null,
        zip: null,
        city: null,
        state: null,
        suiteAptNum: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step5))
