import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step4 ({ theme, values, isLoading, handleFieldChange }) {
    const { t } = useTranslation(transDomain)
    return (
        <>
            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn className={styles.row} size={1}>
                        <Form.Field
                            defaultValue={values.fullName || null}
                            onChange={value => handleFieldChange('fullName', value)}
                            type='text'
                            label={t('label.fullName')}
                            placeholder={t('placeholder.fullName')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={0.5} className={styles.row1}>
                        <Form.Field
                            defaultValue={values.cardNumber || null}
                            onChange={value => handleFieldChange('cardNumber', value)}
                            type='credit-card'
                            label={t('label.creditCardNumber')}
                            placeholder={t('placeholder.creditCardNumber')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={0.25} className={styles.row2}>
                        <Form.Field
                            defaultValue={''}
                            onChange={value => handleFieldChange('month', value)}
                            type='credit-card-month'
                            label={t('label.month')}
                            placeholder={t('placeholder.month')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={0.25} className={styles.row3}>
                        <Form.Field
                            defaultValue={''}
                            onChange={value => handleFieldChange('year', value)}
                            type='credit-card-year'
                            label={t('label.year')}
                            placeholder={t('placeholder.year')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns>
                    <Form.RowColumn size={1 / 2} className={styles.row4}>
                        <Form.Field
                            defaultValue={''}
                            onChange={value => handleFieldChange('cardCode', value)}
                            type='text'
                            label={t('label.cardCode')}
                            placeholder={t('placeholder.cardCode')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1 / 2} className={styles.row5}>
                        <Form.Field
                            defaultValue={values.zip || null}
                            onChange={value => handleFieldChange('zip', value)}
                            type='text'
                            label={t('label.zip')}
                            placeholder={t('placeholder.zip')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continue')}
                        style={{
                            background: theme.background,
                            color: theme.color,
                            marginTop: '0.5em',
                            marginBottom: '1.5em'
                        }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continueAlternate')}
                        style={{
                            background: theme.background,
                            color: theme.color,
                            marginTop: '0.5em',
                            marginBottom: '1.5em'
                        }}
                    />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step4.defaultProps = {
    values: {
        fullName: null,
        cardNumber: null,
        month: null,
        year: null,
        cardCode: null,
        zip: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step4))
