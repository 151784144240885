import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import { Currency } from 'i18n/NumberFormatter'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'
import { Store } from 'Store.js'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

function Step6 ({ theme, values, isLoading, currency, locale }) {
    const { t } = useTranslation(transDomain)
    const {
        donationAmount,
        nameOnCard,
        creditCardNumber,
        cardType,
        cvv,
        cardExpiry
    } = values
    const { state } = React.useContext(Store)

    const sameAsContact = !values.sameAsContact ? null : true
    const billingAddress = sameAsContact ? values.addressLine1 : values.billingAddress
    const billingSuiteAptNum = sameAsContact ? values.addressLine2 : values.billingSuiteAptNum
    const billingCity = sameAsContact ? values.city : values.billingCity
    const billingState = sameAsContact ? values.province : values.billingState
    const billingZipCode = sameAsContact ? values.postalCode : values.billingZipCode
    const currentValue = donationAmount
    const currencyFormatter = Currency(state.currency, state.locale)

    return (
        <>
            <Steps
                step={3}
                steps={3}
                description={<><strong>Payment - </strong>Donation review.</>}
            />

            <Form.Row>
                <Form.RowColumns columnsTablet={4} columnsMobile={2}>
                    <Form.RowColumn size={1} className={styles.row}>
                        <div className={styles.donation}>
                            <div className={styles.donationTitle}>Donation amount</div>
                            <span className={styles.donationAmount}>{currencyFormatter.format(currentValue)}</span>
                        </div>
                    </Form.RowColumn>

                    <Form.RowColumn size={0.5} className={styles.row1}>
                        <div className={styles.donationSummary}>
                            <div className={styles.summaryTitle}>Payment method</div>
                            <div className={styles.summaryList}>
                                <div className={styles.summaryItem}>{nameOnCard}</div>
                                <div className={styles.summaryItem}>{cardType}</div>
                                <div className={styles.summaryItem}>{creditCardNumber}</div>
                                <div className={styles.summaryItem}>{cardExpiry}</div>
                                <div className={styles.summaryItem}>{cvv}</div>
                            </div>
                        </div>
                    </Form.RowColumn>

                    <Form.RowColumn size={0.5} className={styles.row2}>
                        <div className={styles.donationSummary}>
                            <div className={styles.summaryTitle}>Billing address</div>
                            <div className={styles.summaryList}>
                                <div className={styles.summaryItem}>{billingAddress} </div>
                                <div className={styles.summaryItem}>{billingSuiteAptNum}</div>
                                <div className={styles.summaryItem}>{billingCity}</div>
                                <div className={styles.summaryItem}>{billingState}</div>
                                <div className={styles.summaryItem}>{billingZipCode} </div>
                            </div>
                        </div>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.Submit
                    isLoading={isLoading}
                    label={t('label.continue')}
                    style={{
                        background: theme.background,
                        color: theme.color,
                        marginTop: '0.5em',
                        marginBottom: '1.5em'
                    }}
                />
            </Form.Row>
        </>
    )
}

Step6.defaultProps = {
    values: {
        nameOnCard: null,
        creditCardNumber: null,
        cardType: null,
        cardExpiry: null,
        cvv: null,
        billingAddress: null,
        billingSuiteAptNum: null,
        billingCity: null,
        billingState: null,
        billingZipCode: null,
        addres: null,
        zip: null,
        city: null,
        state: null,
        suiteAptNum: null
    }
}

export default withForm()(withTheme(supportedThemes)(Step6))
