// @flow
import * as React from 'react'
import { processPayment, processSubscription } from 'Actions'
import { Store } from 'Store'
import Step4 from '../../LeadGenForm/Step4'

const DEMO_MODE_TEST = /[?&]demo(=|&|$)/
const IsDemoMode = DEMO_MODE_TEST.test(window.location.search)
const ACCEPT_JS_SCRIPT_ID = 'accept-js-script'

export default function ({ onSuccess }) {
    const { state } = React.useContext(Store)
    const fields = state.lead.fields
    const [status, setStatus] = React.useState('pending')

    React.useEffect(() => {
        if (status === 'success') {
            onSuccess()
        }
    }, [status, onSuccess])

    async function responseHandler (data) {
        if (typeof data.messages.message[0] !== 'undefined' && data.messages.message[0].code === 'E_WC_14') {
            /**
             * Accept.js makes an OPTIONS request due to CORS and it returns a bullshit response.
             * apparently the only solution is to ignore the response :|
             * https://community.developer.authorize.net/t5/Integration-and-Testing/Accept-js-E00003-Root-element-is-missing/m-p/63912#M37926
             */
            return
        }

        if (data.messages.resultCode !== 'Ok') {
            alert(data.messages.message[0].text)
            console.error(data)
            return
        }

        switch (state.lead.fields.donationType) {
            case 'one-time':
                try {
                    await processPayment(fields.donationAmount, data.opaqueData)
                    setStatus('success')
                } finally {
                }

                break
            case 'monthly':
                try {
                    processSubscription(fields.donationAmount, data.opaqueData)
                    setStatus('success')
                } finally {
                }
                break
            default:
                alert('Something went wrong')
                console.error(`Unrecognized donationType '${state.lead.fields.donationType}'`)
        }
    }

    function submitHandler (data) {
        data.cardNumber = data.cardNumber.replace(/[^\d]/g, '')

        const dispatchData = {
            authData: {
                clientKey: state.campaign['payment-gateways']['authorize.net'].clientKey,
                apiLoginID: state.campaign['payment-gateways']['authorize.net'].apiLoginID
            },
            cardData: data
        }

        window.Accept.dispatchData(dispatchData, async data => {
            await responseHandler(data)
        })
    }

    React.useEffect(() => {
        const script = document.createElement('script')
        script.id = ACCEPT_JS_SCRIPT_ID

        if (IsDemoMode) {
            script.src = 'https://jstest.authorize.net/v1/Accept.js'
        } else {
            script.src = 'https://js.authorize.net/v1/Accept.js'
        }

        document.body.appendChild(script)

        return () => document.body.removeChild(document.getElementById(ACCEPT_JS_SCRIPT_ID))
    }, [])

    return (
        <>
            <Step4
                onSubmit={submitHandler}
                initialValues={{
                    fullName: `${fields.firstName} ${fields.lastName}`.trim(),
                    zip: fields.postalCode,
                    cardNumber: null,
                    month: null,
                    year: null,
                    cardCode: null
                }}
            />
        </>
    )
}
