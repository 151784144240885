import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import { Store } from 'Store'
import { Redirect } from 'react-router-dom'
import { updateLead } from 'Actions'

import styles from './styles.module.scss'
import Step2 from 'components/LeadGenForm/Step2'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

export default withTheme(supportedThemes)(({ history, location, match, theme }) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-3${location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${match.params.url}${location.search}`} push />
    }

    const firstName = state.lead.fields.firstName

    return (
        <BodyLayout className={styles.container}>
            <LeftSide
                title={<span>{firstName}, thank you for helping us <strong>change lives.</strong></span>}
                className={styles.imgContainer}
                style={{
                    backgroundImage: isIE11 && `url(${theme.sideDesktop})`,
                    '--background-image-desktop': `url(${theme.sideDesktop})`,
                    '--background-image-mobile': `url(${theme.sideMobile})`
                }}
            >
            </LeftSide>
            <RightSide style={{ backgroundColor: theme.backgroundColor }}>
                <Step2
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                    />
            </RightSide>
        </BodyLayout>
    )
})
