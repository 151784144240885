import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import styles from './styles.module.scss'
import Step1 from 'components/MaKeADonation'
import themes from './themes/__supportedThemes'
import withTheme from 'hoc/withTheme'

export default withTheme(themes)(({
    history,
    location,
    theme
}) => {
    return (
        <BodyLayout className={styles.container}>
            <LeftSide title={<>Jonathan, thank you for helping us <strong>change lives.</strong></>} className={styles.imgContainer} style={{
                backgroundImage: `url(${theme.leftSide})`
            }}>
            </LeftSide>
            <RightSide style={{ backgroundColor: '#F9F5F1' }}>
                <Step1
                    handleDonateOnline={() => {
                        window.scrollTo(0, 0)
                        history.push(`step-1${location.search}`)
                    }}
                    handleDonateCall={() => {
                        history.push(`step-3${location.search}&by-phone=true`)
                    }}
                    handleDonateEmail={() => {
                        history.push(`step-3${location.search}&by-email=true`)
                    }}
                />
            </RightSide>
        </BodyLayout>
    )
})
