// @flow
import React from 'react'
import { BodyLayout, LeftSide, RightSide } from 'components/MainLayout'
import { Store } from 'Store'
import styles from './styles.module.scss'
import Step1 from 'components/LeadGenForm/Step1'
import { Redirect } from 'react-router-dom'

import { updateLead } from 'Actions'
import withTheme from 'hoc/withTheme'
import themes, { type Theme } from './themes/__supportedThemes'

function Step1Page (props: { history: any, location: any, match: any, theme: Theme }) {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`step-2${props.location.search}`} push />
    }

    if (!state.lead) {
        return <Redirect to={`/${props.match.params.url}${props.location.search}`} push />
    }

    const firstName = state.lead.fields.firstName

    return (
        <BodyLayout className={styles.container}>
            <LeftSide
                title={<span>{firstName}, thank you for helping us <strong>change lives.</strong></span>}
                className={styles.imgContainer}
                style={{
                    '--background-image-desktop': `url(${props.theme.sideDesktop})`,
                    '--background-image-mobile': `url(${props.theme.sideMobile})`
                }}>
            </LeftSide>
            <RightSide style={{ backgroundColor: props.theme.backgroundColor }}>
                <Step1
                    onSubmit={async (data, done) => {
                        try {
                            let lead = {
                                fields: data,
                                isComplete: false
                            }
                            await updateLead(dispatch, state.code, lead)
                            setSubmitted(true)
                            window.scrollTo(0, 0)
                        } catch (err) {
                            alert('Oops something went wrong')
                            console.error(err)
                            done(err)
                        }
                    }}
                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                />
            </RightSide>
        </BodyLayout>
    )
}

export default withTheme(themes)(Step1Page)
