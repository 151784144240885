// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
import StepLayout from 'components/StepLayout'
import Countdown from 'components/Countdown'
import AbsoluteTime from 'components/AbsoluteTime'
import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'
import Typography from '../Typography'
// import Content from '../Content'

Landing.defaultProps = {
    date: new Date()
}

function Landing (props: Props) {
    const { theme } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    return (
        <StepLayout>
            <Typography isCentered>
                <div className={styles.expiryText}>
                    <div className={styles.eventExpiry}><span>{t('event_expiry')}&nbsp;<AbsoluteTime dateFormat='long' date={state.campaign.expiryDate}/></span></div>
                    <div className={styles.countdown}><Countdown date={state.campaign.expiryDate}/></div>
                </div>
                <h1 className={styles.title}>
                    {state.lead.fields.firstName}, thank you for helping us <strong>change lives.</strong>
                </h1>
                <p className={styles.message}>
                    Support from alumni, community and corporate sponsors have provided countless opportunities for our students to thrive.
                </p>
                <Form.Submit
                    isLoading={props.isLoading}
                    label={t('continueToStep2')}
                    style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                />
            </Typography>
        </StepLayout>
    )
}

export default withForm()(withTheme(supportedThemes)(Landing))
