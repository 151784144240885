/* eslint-disable quotes */
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import transDomain from './translations/index.translations'

import * as Form from 'components/Form'
import withForm from 'components/Form/Helpers/FormHOC'
import Steps from 'components/Steps'
import DonateOption from 'components/DonateOption'
import SwitchSelector from 'components/SwitchSelector'
import ModalContainer from 'components/ModalContainer'
import { Currency } from 'i18n/NumberFormatter'
import messageImage from './images/messageImage.jpg'

import supportedThemes from './themes/__supportedThemes'
import { Store } from 'Store'

import styles from './styles.module.scss'

function Step3 ({ theme, values, isLoading, byEmail, byPhone, toStep1, toEmail, toPhone, handleFieldChange }) {
    const [show, setShow] = React.useState(byEmail || byPhone)
    const [ otherAmountActive, setOtherAmountActive ] = React.useState(null)
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)

    values.anonymousDonation = values.anonymousDonation || false

    const handleSetOtherAmountActive = React.useCallback(() => {
        setOtherAmountActive(true)
    }, [])

    const handleSetOtherAmountDisable = React.useCallback(() => {
        setOtherAmountActive(false)
    }, [])

    const currencyFormatter = React.useMemo(
        () => Currency(state.currency, state.locale),
        [state.currency, state.locale]
    )

    const donationAmountOptions = React.useMemo(() => {
        return state.campaign.website.donationAmounts
    }, [state.campaign.website.donationAmounts])

    const chunkArray = (array, size) => {
        const chunkedArr = []
        let index = 0
        while (index < array.length) {
            chunkedArr.push(array.slice(index, size + index))
            index += size
        }
        return chunkedArr
    }

    React.useEffect(() => {
        if (values.donationAmount === null || !donationAmountOptions.includes(values.donationAmount)) {
            handleSetOtherAmountActive()
        }
    }, [ values.donationAmount, donationAmountOptions, handleSetOtherAmountActive ])

    return (
        <>
            <ModalContainer show={show} onClickOverlay={() => setShow(true)}>

                {byPhone &&
                    <div className={styles.infoContainer} style={{ backgroundImage: `url(${messageImage})` }}>
                        <p className={styles.infoTitle}>Phone call donation</p>
                        <div className={styles.infoBody}>
                            <p>{t('byPhone.title')}</p>
                            <span>{t('byPhone.thanksMessage')}</span>
                        </div>
                        <span onClick={toStep1}>
                            <Form.Submit
                                isSubmit={false}
                                isLoading={isLoading}
                                label={t('label.continue')}
                                style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                            />
                        </span>
                        <div className={styles.infoMessage} onClick={toEmail}>{t('byEmail.alternative')}</div>
                    </div>
                }

                {byEmail &&
                    <div className={styles.infoContainer} style={{ backgroundImage: `url(${messageImage})` }}>
                        <p className={styles.infoTitle}>Donate by mail</p>
                        <div className={styles.infoBody}>
                            <p>{t('byPhone.title')}</p>
                            <span>{t('byPhone.thanksMessage')}</span>
                        </div>
                        <span onClick={toStep1}>
                            <Form.Submit
                                isSubmit={false}
                                isLoading={isLoading}
                                label={t('label.continue')}
                                style={{ background: theme.background, color: theme.color, marginTop: '1.62em' }}
                            />
                        </span>
                        <div className={styles.infoMessage} onClick={toPhone}>{t('byPhone.alternative')}</div>
                    </div>
                }

            </ModalContainer>

            <Steps step={3} steps={3} description={'Select the amount you wish to donate.'} title={''}/>

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <SwitchSelector
                            selected={values.donationType}
                            options={['one-time', 'monthly']}
                            required
                            handleInputChange={value => handleFieldChange('donationType', value)}
                            trans={t}
                            labelFormat='donationType.option.%name%'
                        />
                        <span className={styles.message}>
                            {t('donation_time', {
                                type: values.donationType
                            })}
                        </span>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <div className={styles.flexContainer}>
                            {chunkArray(donationAmountOptions, 3).map((chunk, key) => (
                                <div className={styles.row}>
                                    {chunk.map((donationAmount, i) => (
                                        donationAmount !== null
                                            ? <DonateOption center small title={currencyFormatter.format(donationAmount)}
                                                onClick={() => {
                                                    // if a user changes the donation amount after he selected a designation
                                                    if (values.donationDesignations && values.donationDesignations.length === 1) {
                                                        const designation = values.donationDesignations[0]
                                                        designation.value = donationAmount
                                                        handleSetOtherAmountDisable()
                                                        // update first field, use callback to update extra state
                                                        handleFieldChange('donationAmount', donationAmount, () => {
                                                            handleFieldChange('donationDesignations', [designation])
                                                        })
                                                    } else {
                                                        handleSetOtherAmountDisable()
                                                        handleFieldChange('donationAmount', donationAmount)
                                                    }
                                                }}
                                                active={values.donationAmount === donationAmount}
                                            />
                                            : <DonateOption center small title='Other'
                                                onClick={() => {
                                                    // if a user changes the donation amount after he selected a designation
                                                    if (values.donationDesignations && values.donationDesignations.length === 1) {
                                                        // prevent double click on "Other" value must not be null or 0
                                                        const designation = values.donationDesignations[0]
                                                        designation.value = values.donationAmount
                                                        // update first field, use callback to update extra state
                                                        handleSetOtherAmountActive()
                                                        handleFieldChange('donationAmount', designation.value, () => {
                                                            handleFieldChange('donationDesignations', [designation])
                                                        })
                                                    } else {
                                                        handleSetOtherAmountActive()
                                                        handleFieldChange('donationAmount', null)
                                                    }
                                                }}
                                                active={values.donationAmount === null || !donationAmountOptions.includes(values.donationAmount)}
                                            />
                                    ))}
                                </div>
                            ))}
                        </div>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            {(otherAmountActive === true || (values.donationAmount === null || !donationAmountOptions.includes(values.donationAmount)) )&&
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.CurrencyField
                                label={t('label.amount')}
                                type='currency'
                                defaultValue={values.donationAmount || ''}
                                onChange={value => {
                                    // if a user changes the donation amount after he selected a designation
                                    if (values.donationDesignations && values.donationDesignations.length === 1) {
                                        const designation = values.donationDesignations[0]
                                        designation.value = value
                                        // update first field, use callback to update extra state
                                        handleFieldChange('donationAmount', value, () => {
                                            handleFieldChange('donationDesignations', [designation])
                                        })
                                    } else {
                                        handleFieldChange('donationAmount', value)
                                    }
                                }}
                                currency={state.currency}
                                locale={state.locale}
                                required={typeof values.donationAmount !== 'undefined' && !donationAmountOptions.includes(values.donationAmount)}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            }

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={0.5}>
                        <Form.Field label={'Please apply my gift to'}>
                            <Form.Dropdown
                                placeholder={'Select'}
                                options={state.campaign.website.donationDesignations}
                                required={true}
                                onChange={value => {
                                    handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                        type: value,
                                        value: values.donationAmount
                                    }] : null)
                                }}
                                defaultValue={values.donationDesignations
                                    ? (values.donationDesignations[0].type
                                        ? values.donationDesignations[0].type
                                        : null
                                    )
                                    : null
                                }
                            />
                        </Form.Field>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <Form.Field label={'Matching gift'}>
                            <div className={styles.donateOptionGroup}>
                                <DonateOption center small title='None'
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'none')}
                                    active={values.matchingGiftCompany === 'none'}
                                    className={styles.option1}
                                />
                                <div className={styles.option2} />
                                <DonateOption center small title='My company'
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'own-company')}
                                    active={values.matchingGiftCompany === 'own-company'}
                                    className={styles.option3}
                                />
                                <div className={styles.option4} />
                                <DonateOption center small title="Spouse’s company"
                                    onClick={() => handleFieldChange('matchingGiftCompany', 'spouse-company')}
                                    active={values.matchingGiftCompany === 'spouse-company'}
                                    className={styles.option5}
                                />
                            </div>
                        </Form.Field>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            {(values.matchingGiftCompany === 'spouse-company' || values.matchingGiftCompany === 'own-company') && values.matchingGiftCompany !== null && values.matchingGiftCompany !== 'none' &&
                <Form.Row>
                    <Form.RowColumns columnsTablet={1}>
                        <Form.RowColumn size={1}>
                            <Form.Field
                                defaultValue={values.matchingGiftCompanyName || null}
                                onChange={value => {
                                    handleFieldChange('matchingGiftCompanyName', `${value}`)
                                }}
                                type='text'
                                label={t('matchingGiftCompanyName.label')}
                                placeholder={t('matchingGiftCompanyName.placeholder')}
                            />
                        </Form.RowColumn>
                    </Form.RowColumns>
                </Form.Row>
            }

            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    <Form.RowColumn size={1}>
                        <span className={styles.chekbox}>
                            <Form.Checkbox
                                defaultChecked={values.anonymousDonation}
                                onChange={() => handleFieldChange('anonymousDonation', !values.anonymousDonation)}
                            />
                            <p>If this box is checked, you will remain anonymous.</p>
                        </span>
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <MediaQuery minWidth={1024}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continue')}
                        style={{ background: theme.background, color: theme.color, marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <Form.Submit
                        isLoading={isLoading}
                        label={t('continueAlternate')}
                        style={{ background: theme.background, color: theme.color, marginTop: '0.5em', marginBottom: '1.5em' }}
                    />
                </MediaQuery>
            </Form.Row>
        </>
    )
}

Step3.defaultProps = {
    values: {
        donationAmount: null,
        matchingGiftCompany: 'None',
        matchingGiftCompanyName: null,
        donationDesignations: null,
        anonymousDonation: false
    }
}

export default withForm()(withTheme(supportedThemes)(Step3))
